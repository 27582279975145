<template>
  <div class="about">
    <div class="title">
      <div class="title-img">
        <img src="~assets/images/icon/start.png" alt="" />
      </div>
      <section>About Us</section>
    </div>
    <div class="A-font" v-html="content"></div>
  </div>
</template>
<script>
import { getArticle } from "network/article";
export default {
  data() {
    return {
      content: "",
    };
  },
  created() {
    this.getArticle(2);
  },
  methods: {
    getArticle(tid) {
      getArticle(tid).then((res) => {
        this.content = res.data.data.article.content;
      });
    },
  },
};
</script>
<style scoped>
.about {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin-top: 0.5rem;
}
.title {
  padding: 0;
  background: #ff7f00;
  display: flex;
  font-size: 0.7rem;
  color: #fff;
  align-items: center;
}
.title-img {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
}
.title-img img {
  width: 0.5rem;
  height: 0.5rem;
}
.title section {
  padding: 0.2rem 0;
}
.A-font {
  font-size: 0.35rem;
  padding: 0.75rem;
  color: #737373;
}
.A-font p {
  font-size: 0.35rem;
}
.A-font span {
  font-size: 0.35rem;
}
</style>
