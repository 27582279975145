import { request } from "./request";


export function getArticle(tid) {
    return request({
        url: '/api/article/getArticleDetail',
        params: {
            tid: tid
        }
    })
};